import { memo, useState, useCallback, useRef, useEffect } from 'react';
import React from "react";
import SignatureCanvas from 'react-signature-canvas';
import { FormGroup, Row, Col } from "reactstrap";

import { v4 as uuidv4 } from 'uuid';

const Signature = ({question, answers, onSaveQuestion, hasErr}) => {

    const [ boxID ] = useState(uuidv4())
    const [ answer, setAnswer ] = useState(answers[question.id] || '')
    const [ dimensions, setDimensions ] = useState({width: 0, height: 0})
    
    const { width, height } = dimensions;
    const sigPadSignature = useRef({});

    const onSave = useCallback(async (_answer) => {
        onSaveQuestion(_answer, question.id)
    }, [onSaveQuestion, question.id])

    const  clearSigPad = useCallback((e) => {
        e.preventDefault();
        sigPadSignature.current.clear();
        setAnswer('')
        onSave('')
    }, [onSave])

    const resizeCanvas = useCallback(() => {
        const box = document.getElementById(boxID)

        if(box) {
            const width = box.offsetWidth
            const height = width / 3

            setDimensions({width, height});
            if(sigPadSignature.current) {
                let signature64 = sigPadSignature.current.getTrimmedCanvas()
                document.querySelector('.sigCanvas').width = width
                document.querySelector('.sigCanvas').height = height
                sigPadSignature.current.fromDataURL(signature64.toDataURL('image/png'))
            }

        }
    }, [boxID])

    const onConfirm = useCallback((e) => {
        e.preventDefault();

        const signature = sigPadSignature.current.getTrimmedCanvas().toDataURL('image/png')

        var image = new Image(); 
        image.src = signature; 

        image.onload = () => {
            if(signature.length > 1000) {
                const base64 = signature.split(',')[1]
                setAnswer(base64)
                onSave(base64)
            }
        };
    }, [onSave])

    useEffect(() => {
        resizeCanvas();
        window.addEventListener('resize', resizeCanvas)
        return () => {
            window.removeEventListener('resize', resizeCanvas)
        }
    }, [resizeCanvas])

    useEffect(() => {
        const value = answers[question.id]
        if(value) setAnswer(value.toString('base64'))
    }, [question, answers])


    return (
        <FormGroup id={question.id} className={hasErr ? 'question-error' : ''}>
              <label className={`form-control-label`}>
                {answer ? <i className="fas fa-check-circle text-success mr-2 " /> : null}
                {question.name}
            </label>

            {question.description ? (
                <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
            ) : !answer ? (
                <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> Draw Your Signature Below.</p>
            ) : ''}

            {answer ? (
                <Row className="mb-3 border-top border-bottom py-3">
                    <Col sm={6} className="align-self-center">
                        <p className=" mb-0 font-weight-bold">Your Current Signature:</p>
                    </Col>
                    <Col sm={6} className="align-self-center text-right">
                        <img src={`data:image/png;base64,${answer}`} className="" style={{maxHeight: 75, maxWidth: '100%'}} alt="uploaded file" />
                    </Col>
                </Row>
            ) : null}

            <div className="signature-box bg-white border" id={boxID}>
                <SignatureCanvas
                    width={width}
                    height={height}
                    clearOnResize={false}
                    backgroundColor="rgba(255,255,255,.0)"
                    penColor='black'
                    throttle={4}
                    maxWidth={3}
                    minDistance={3}
                    canvasProps={{className: 'sigCanvas' }}
                    ref={sigPadSignature}
                />
            </div>

            <div className="text-right mt-4">
                <div className="mb-3">
                    <button className="btn btn-warning" onClick={clearSigPad}>Clear Signature</button>
                    <button className="btn btn-success" onClick={onConfirm}>Save Signature</button>
                </div>

                {/* {hasErr ? (
                    <p className="mb-0 text-left"><i className="fas fa-info-circle text-info " /> Make sure you <b className="text-underline">save your signature</b> after you have added it by clicking <b>"Save Signature</b> above.</p>
                ) : null} */}
            </div>
        </FormGroup>
    )

}

export default memo(Signature)