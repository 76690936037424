
import { memo, useState, useCallback } from 'react';

import StateInput from 'components/markup/inputs/States';

import MapQuestions from '../MapQuestions';

const QuestionState = ({question, answers, onSaveQuestion, custom_fields, newLoop, errors, hasErr}) => {

    const [ answer, setAnswer ] = useState(answers[question.id] || '')

    const onSave = useCallback(async (_answer) => {
        onSaveQuestion(_answer, question.id)
    }, [onSaveQuestion, question.id])

    return (
        <div className={hasErr ? 'question-error' : ''}>

            <StateInput 
                title={question.name}
                value={answer}
                placeholder=" "
                onChange={(state) => {
                    setAnswer(state)
                    onSave(state)
                }}
            />

            {answer && question.branch_on_answer && question.branch_on_answers.includes(answer) ? (
                <MapQuestions 
                    parentLoop={newLoop}
                    questions={question.questions}
                    answers={answers}
                    custom_fields={custom_fields}
                    onSaveQuestion={onSaveQuestion}
                    errors={errors}
                    hasErr={errors.includes(question.id)}
                />
            ) : ''}
        </div>
    )
}

export default memo(QuestionState);