
import { memo } from 'react';
import Question from './Question';



const MapQuestions = ({questions, answers, custom_fields, parentLoop, onSaveQuestion, errors}) => (
    <div className="question-list position-relative" >

        {/* <i className="indent-icon fas fa-reply text-dark fa-rotate-180" /> */}

        {parentLoop ? (
            <i 
                className="indent-icon fas fa-reply text-info fa-rotate-180"  
                style={{position: 'absolute', left: 14, top: -8}}
            />
        ) : ''}

        <div className="question-list-inner">

            {questions.map((question, index) => (
                <div className='question' key={question.id} data-id={question.id}>
                    <p className={`text-sm mb--1 font-weight-bold ${errors.includes(question.id) ? 'text-danger' : 'text-muted'}`}>
                        {question.required ? '*' : ''}Question {parentLoop ? parentLoop + '.' : ''}{index + 1} 
                    </p>
                    <Question 
                        key={index}
                        index={index}
                        onSaveQuestion={onSaveQuestion}
                        parentLoop={parentLoop}
                        question={question}
                        answers={answers}
                        custom_fields={custom_fields}
                        errors={errors}
                        hasErr={errors.includes(question.id)}
                    />
                </div>
            ))}

        </div>
    </div>
)

export default memo(MapQuestions);