import { memo, useState, useCallback, useEffect } from 'react';
import { FormGroup, Form } from 'reactstrap'

import { v4 as uuidv4 } from 'uuid';

import MapQuestions from '../MapQuestions';

const QuestionCheckBox = ({question, answers, custom_fields, newLoop, onSaveQuestion, errors, hasErr}) => {

    const [ answer, setAnswer ] = useState(answers[question.id] || [])
    const [ ids, setIds ] = useState([])

    let canBranch = false;

    console.log(answer)

    answer.forEach(a => {
        if(question.branch_on_answer && question.branch_on_answers.includes(a)) canBranch = true;
    })

    const handleChange = useCallback(async (val) => {
        let _answer = JSON.parse(JSON.stringify(answer));
    
        if(_answer.includes(val)) {
            _answer = _answer.filter(v => v !== val);
        } else {
            _answer.push(val)
        }
        setAnswer(_answer)
        onSaveQuestion(_answer, question.id)

    }, [answer, question.id, onSaveQuestion])
    
    useEffect(() => {
        const _ids = []
        question.answers.forEach(() => _ids.push(uuidv4()))
        setIds(_ids)
    }, [question, answers])

    return (
        <div className={hasErr ? 'question-error' : ''}>

            <Form id={question.id} onSubmit={(e) => e.preventDefault()}>
                <FormGroup >        
                    <label className={`form-control-label `}>
                        {question.name}
                    </label>

                    {question.description ? (
                        <p className="text-sm mb-0 mt--2 mb-2"><i className="fas fa-info-circle mr-2 text-info " /> {question.description}</p>
                    ) : null}

                    {question.answers.map((a, i) => {
                        const selected = answer.includes(a)
                        return (
                            <div key={i} className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id={ids[i]}
                                    type="checkbox"
                                    onChange={() => handleChange(a)}
                                    checked={selected}
                                />
                                <label className="custom-control-label" htmlFor={ids[i]}>
                                    {a}
                                </label>
                            </div>
                        )
                    })}
                </FormGroup>  
            </Form>

            {canBranch ? (
                <MapQuestions 
                    parentLoop={newLoop}
                    questions={question.questions}
                    answers={answers}
                    custom_fields={custom_fields}
                    onSaveQuestion={onSaveQuestion}
                    errors={errors}
                    hasErr={errors.includes(question.id)}
                />
            ) : ''}

        </div>   
    )
}

export default memo(QuestionCheckBox);