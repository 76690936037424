import store from 'store';
import keys from 'keys';

import errorLog from './errorLog'

import Axios from 'axios';

const request = (domain, method, url, data) => {
    return new Promise (async resolve => {

        const state = store.getState()
        const domains = state.load.DOMAINS;
        const baseDomain = domains ? domains[domain] : keys.API_URL;
        const user = state.auth.viewing_user;

        const start = Math.floor(new Date() / 1)

        try {
            const result = await Axios({
                method,
                url: baseDomain + url,
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
                data,
            })

            const end = Math.floor(new Date() / 1)
            if(user && user.settings.log_errors) errorLog.store(result, start, end)

            resolve(result.data)

        } catch(e) {

            const end = Math.floor(new Date() / 1)
            if(user && user.settings.log_errors) errorLog.store(e, start, end)

            try {
                const err = JSON.parse(JSON.stringify(e.response.data))
                console.log(err)
                if(err && err.error === 'THREAT DETECTED') {
                    return window.location.href = '/errors/threat'
                }

                return resolve({success: false, message: err})
            } catch(e) {
                console.log(e)
                return resolve({success: false, message: e})
            }
        
        }

    })
}

export default request;