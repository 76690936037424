import { Helmet } from 'react-helmet';

import { useEffect } from 'react';
import { Col, Container, Row, Card, CardBody } from "reactstrap";

import { connect } from 'react-redux'

import Home from './images/Home2.svg'

const HomePage = ({LOGO}) => {

    useEffect(() => {



    }, [])


    return (
        <>

			<Helmet>
				<title>IntuiDesk</title>
				<meta name="description" content="IntuiDesk" />
			</Helmet>


			<Container className="my-4 my-lg py-lg-9 p-0">
                <Row>
                    <Col lg={5} className="align-self-center">
                       <Card className="z-depth-4">
                           <CardBody className="bg-dark">
                           {/* <img style={{width: 300}} alt="IntuiDesk" className='mb-4'  src={LOGO} /> */}
                            <h2 className='text-white'>IntuiDesk</h2>
                            <p className="text-white font-weight-bold mt-2" >Online Questionnaires In Seconds</p>

                            <hr style={{borderBottom: 'solid 1px white'}}/>

                            <p className="text-white" >Your information is right around the corner.</p>
                            <p className="text-white font-weight-bold" >To get started, please click the link that was either emailed to you or texted to your phone.</p>
                            
                            <hr style={{borderBottom: 'solid 1px white'}}/>

                            <p className="text-white font-weight-bold" ><i className="fas fa-lock mr-3 " /> 
                                <span className="text-underline">
                                    Your information is safe, secure, and will never be sold to an outside party without your consent.
                                </span>
                            </p>
                           </CardBody>
                       </Card>
                    </Col>
                    <Col lg={1} className="align-self-center"/>
                    <Col lg={6} className="align-self-center">
                        <img src={Home} className="w-100" alt="..." />
                    </Col>
                </Row>
			</Container>
		</>

    )
}


const mapStateToProps = state => {
	return {
	    LOGO: state.load.PLATFORM.LOGO,
	};
};

export default connect(mapStateToProps, '')(HomePage);


