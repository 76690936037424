import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";

const Forms = () => (
    <Switch>
        {routes.map((prop, key) => (
            <Route
                path={`/${prop.path}`}
                component={prop.component}
                key={key}
            />
        ))}
    </Switch>
)

export default Forms
