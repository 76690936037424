
import { memo, useState, useCallback } from 'react';
import { FormGroup } from 'reactstrap'

import PhoneInput from 'react-phone-input-2'

const QuestionPhone = ({question, answers, onSaveQuestion, hasErr}) => {

    const [ answer, setAnswer ] = useState(answers[question.id] || '')

    const onSave = useCallback(async () => {
        onSaveQuestion(answer, question.id)
    }, [answer, onSaveQuestion, question.id])

    return (
        <FormGroup className={hasErr ? 'question-error' : ''}>
            <label className="form-control-label">{question.name}</label>
            {question.description ? <p className='text-sm mb-0'>{question.description}</p> : ''}
            <PhoneInput
                country={'us'}
                specialLabel=""
                value={answer}
                onBlur={onSave}
                onChange={phone => setAnswer(phone)}
            />    
        </FormGroup>

    )
}

export default memo(QuestionPhone);