import r from './request'

const api = {

    load: {
        standard    : ()    => r('SERVER', 'get',   `/v1/load/standard`),
        config      : ()    => r('SERVER', 'get',   `/v1/load/config`),
    },

    forms: {
        find        : (i)    =>  r('SERVER', 'get', `/v1/form_actions/${i}`),
        save        : (i, d) =>  r('SERVER', 'post', `/v1/form_actions/${i}/save`, d),
        progress    : (i, d) =>  r('SERVER', 'post', `/v1/form_actions/${i}/progress`, d),
        submit      : (i, d) =>  r('SERVER', 'post', `/v1/form_actions/${i}`, d),
    },
}

export default api;