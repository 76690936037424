import { memo, useState, useCallback } from 'react';
import { FormGroup } from 'reactstrap'

import DatePicker from 'react-datepicker'

const QuestionDate = ({question, answers, onSaveQuestion, hasErr}) => {

    const [ answer, setAnswer ] = useState(answers[question.id] || '')

    const onSave = useCallback(async (_answer) => {
        onSaveQuestion(_answer, question.id)
    }, [onSaveQuestion, question.id])

    return (
        <FormGroup className={hasErr ? 'question-error' : ''}>
            <label className="form-control-label">{question.name}</label>
            {question.description ? <p className='text-sm mb-0'>{question.description}</p> : ''}
            <DatePicker 
                showYearDropdown
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                onBlur={() => onSave(answer)}
                selected={answer} 
                onChange={(date) => {
                    setAnswer(date)
                    onSave(date)
                }} 
            />
        </FormGroup>

    )
}

export default memo(QuestionDate);