import { useState, useEffect } from 'react';

import ContactPhone from './ContactPhone'
import ContactEmail from './ContactEmail'
import ContactName from './ContactName'
import ContactAddress from './ContactAddress'

import Text from './Text'
import YesNo from './YesNo'
import Number from './Number'
import Date from './Date'
import State from './State'
import Select from './Select'
import CheckBox from './CheckBox'
import Signature from './Signature'

const Question = ({question, answers, custom_fields, index, parentLoop, onSaveQuestion, errors, hasErr}) => {
    const newLoop = parentLoop ? parentLoop + '.' + parseInt(index + 1) : index + 1;

    const [_question, _setQuestion] = useState(null);

    useEffect(() => {
        if(question.type === 'custom-field') {
            const foundField = custom_fields.find(f => f._id === question.custom_field);
            if(foundField) {
                const q = JSON.parse(JSON.stringify(question))
                q.answers = foundField.answers;
                q.type = foundField.type;
                return _setQuestion(q)
            }
        }
        _setQuestion(question)
    }, [custom_fields, question])

    if(!_question) return <></>;

    if(_question.type === 'text' || _question.type === 'textarea') return (
        <Text 
            question={_question} 
            answers={answers} 
            type={_question.type}
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
        />
    ) 

    if(_question.type === 'phone' || _question.type === 'phone-2') return (
        <ContactPhone 
            question={_question} 
            answers={answers} 
            type={_question.type}
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
        />
    ) 

    if(_question.type === 'email' || _question.type === 'email-2') return (
        <ContactEmail
            question={_question} 
            answers={answers} 
            type={_question.type}
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
        />
    )

    if(_question.type === 'given-name' || _question.type === 'family-name') return (
        <ContactName
            question={_question} 
            answers={answers} 
            type={_question.type}
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
        />
    ) 
    if(_question.type === 'address-contact' || _question.type === 'address') return (
        <ContactAddress
            question={_question} 
            answers={answers} 
            type={_question.type}
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
        />
    ) 
    
    if(_question.type === 'yes-no') return (
        <YesNo 
            question={_question} 
            answers={answers} 
            custom_fields={custom_fields} 
            newLoop={newLoop}
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
            errors={errors}
        />
    ) 

    if(_question.type === 'number') return (
        <Number 
            question={_question} 
            answers={answers} 
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
        />
    )

    if(_question.type === 'date') return (
        <Date 
            question={_question} 
            answers={answers} 
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
        />
    )
    
    if(_question.type === 'state') return (
        <State 
            question={_question} 
            answers={answers} 
            custom_fields={custom_fields} 
            newLoop={newLoop}
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
            errors={errors}
        />
    )
    
    if(_question.type === 'select') return (
        <Select 
            question={_question} 
            answers={answers} 
            custom_fields={custom_fields} 
            newLoop={newLoop}
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
            errors={errors}
        />
    )
   
    if(_question.type === 'checkbox') return (
        <CheckBox 
            question={_question} 
            answers={answers} 
            custom_fields={custom_fields} 
            newLoop={newLoop}
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
            errors={errors}
        />
    )
    if(_question.type === 'signature') return (
        <Signature 
            question={_question} 
            answers={answers} 
            onSaveQuestion={onSaveQuestion}
            hasErr={hasErr}
        />
    )

    return (
        <div>
            {_question.name}
            <div className="alert alert-danger">Type {_question.type} no found.</div>
        </div>
    )

}

export default Question;
//  "custom-field",
// "given-name",
// "family-name",
// "email",
// "email-2",
// "phone",
// "phone-2",
// "address-contact",

// "text",
// "textarea",
// "number",
// "state",
// "yes-no",
// "select",
// "checkbox",
// "date",
// "signature",
