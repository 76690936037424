
import { useCallback, useEffect, useState } from 'react';
import { Container  } from 'reactstrap'
import { Helmet } from 'react-helmet';

import api from 'api';
import moment from 'moment';

import A from 'components/markup/links/A'
import Circle from 'components/markup/loading/Circle'

import { toast } from 'react-toastify'
import { toggleStandardLoader } from 'store/functions/system/system';

import Submitted from './Submitted'
import StepTracker from './StepTracker'
import MapQuestions from './MapQuestions'

let isSaving = false;

const Forms = ({match}) => {

    const [err, setErr] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState([]);

    const [ page, setPage ] = useState(0)
    const [ pageQuestions, setPageQuestions ] = useState([])

    const [ state, setState ] = useState({
        company_name: '',
        company: '',
        contact: '',
        division: '',
        matter: '',
        form: {},
        form_answers: {},
        custom_fields: []
    })

    const fetchData = useCallback(async (cb) => {
        const data = await api.forms.find(match.params.form_id)
        if(!data.data) {
            if(data.message && data.message[0] && data.message[0].includes('The form')) return setErr(data.message[0]);
            return setErr(`This form cannot be accessed at this time, please contact support or try refreshing your page.`)
        }

        const { company, company_name, contact, division, matter, form, form_answers, custom_fields } = data.data;
        setState({ company, contact, division, matter, form, form_answers, custom_fields, company_name  })
        setPageQuestions(form.questions.filter(f => f.page === 0));
        if(cb) cb()

    }, [match.params.form_id])

    const moveForward = useCallback(async (e, tries = 0) => {
        const newPage = page + 1;
        toggleStandardLoader(true)

        if(isSaving && tries < 10) return setTimeout(() => {
            moveForward(null, tries + 1)
        }, 250)

        const data = await api.forms.progress(state.form_answers._id, { page })
        
        if(!data.success) {
            toggleStandardLoader(false)
            return toast.info('Something went wrong loading this form, please try again.')
        }

        if(data.data.errors.length) {
            const el = document.querySelector(`[data-id="${data.data.errors[0]}"]`);
            if(el) el.scrollIntoView({ behavior: 'smooth' })
            toast.info(`Please fill out all required questions on this page before moving forward.`)
            toggleStandardLoader(false)
            return setErrors(data.data.errors);
        }

        if(newPage >= state.form.pages) {
            const submitted = await api.forms.submit(state.form_answers._id)
            toggleStandardLoader(false)

            if(!submitted.success) {
                let defaultMessage = `Something went wrong submitting this form,  please try again`;
                try {
                    toast.error(submitted.message[0].includes('The form you are') ? submitted.message[0] : defaultMessage)
                } catch(e) {

                }
                return toast.error(defaultMessage)
            }

            if(state.form.redirect_url) return window.location.href = state.form.redirect_url;
            setSubmitted(true)

        } else {
            fetchData(() => {
                toggleStandardLoader(false)
                const _pageQuestions = state.form.questions.filter(q => q.page === newPage)
                setPageQuestions(_pageQuestions)
                setPage(newPage)
                setErrors([])
            })
        }

       
       
    }, [page, state.form.questions, state.form.pages, state.form_answers._id, state.form.redirect_url, fetchData])

    const moveBack = useCallback(() => {
        toggleStandardLoader(true)
        fetchData(() => {
            toggleStandardLoader(false)
            const newPage = page - 1 >= 0 ? page - 1 : page;
            const _pageQuestions = state.form.questions.filter(q => q.page === newPage)
            setPageQuestions(_pageQuestions)
            setPage(newPage)
        })
       
    }, [page, state.form.questions, fetchData])

    const onChangeStep = useCallback(() => {

    }, [])

    const onSaveQuestion = useCallback(async (answer, question_id) => {
        isSaving = true;
        const saved = await api.forms.save(state.form_answers._id, { answer, question_id })
        isSaving = false;
        if(!saved.success) console.log(saved);
    }, [state.form_answers._id])

   

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return (
        <div className="alert alert-warning text-center">
            <i className="fas fa-exclamation-triangle mr-2 " /> {err}
        </div>
    )

    const btnText = page === state.form.pages -1 ? 
        <span>Submit <i className="fas fa-paper-plane ml-2 " /></span> : 
        <span>Next Page <i className="fas fa-arrow-right ml-2 " /></span>

    if(!state.company) return <div className='py-6'><Circle /></div>
    if(submitted) return <Submitted />

    return (
        <div>

            <Helmet>
				<title>{state.company_name}</title>
				<meta name="description" content="IntuiDesk" />
			</Helmet>

            <div className='borde my-4 pt-4 '>
                <Container className="">
                    <StepTracker 
                        currentPage={page + 1}
                        onChangeStep={onChangeStep}
                        form={state.form}
                        moveForward={moveForward}
                        moveBack={moveBack}
                        btnText={btnText}
                    />
                </Container>
            </div>

            <Container>

                {/* <h2 className='display-2 text-center'>FORM: {state.form.name}</h2> */}

                <MapQuestions 
                    questions={pageQuestions}
                    answers={state.form_answers.answers}
                    custom_fields={state.custom_fields}
                    onSaveQuestion={onSaveQuestion}
                    errors={errors}
                />

                <div className='text-center pt-4'>
                    <button onClick={moveForward} className="px-4 btn btn-success">
                        {btnText}
                    </button>
                </div>
            </Container>

            <hr />

            <Container className='py-7'>
                <p className="text-sm">
                    This form is powered by IntuiDesk on behalf of <b>{state.company_name}.</b> All information is protected and secured. For any questions regarding use of this web page reach out to the company that sent you this link. To view the IntuiDesk Terms Of Service <A className="text-underline text-dark font-weight-bold" href="https://intuidesk.com/terms-of-service">CLICK HERE.</A> To view the IntuiDesk Privacy Policy <A className="text-underline text-dark font-weight-bold" href="https://intuidesk.com/privacy">CLICK HERE.</A>
                </p>
                <p className="text-sm">
                    &copy; {moment().format('YYYY')} IntuiDesk LLC
                </p>
            </Container>
        </div>
    )

}

export default Forms;