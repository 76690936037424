
import { memo, useState, useCallback } from 'react';
import { FormGroup, Input } from 'reactstrap'

const QuestionEmail = ({question, answers, onSaveQuestion, hasErr}) => {

    const [ answer, setAnswer ] = useState(answers[question.id] || '')

    const onSave = useCallback(async () => {
        onSaveQuestion(answer, question.id)
    }, [answer, onSaveQuestion, question.id])

    return (
        <FormGroup className={hasErr ? 'question-error' : ''}>
            <label className="form-control-label">{question.name}</label>
            {question.description ? <p className='text-sm mb-0'>{question.description}</p> : ''}
            <Input 
                onBlur={onSave}
                onChange={e => setAnswer(e.target.value)}
                value={answer}
                type="text"
            />
        </FormGroup>
    )
}

export default memo(QuestionEmail);