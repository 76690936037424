import { Helmet } from 'react-helmet';
import Image from './image.svg'

const Submitted = () => (
    <div className='text-center py-7 px-4'>

        <Helmet>
            <title>Thank You!</title>
            <meta name="description" content="Thank You!" />
        </Helmet>

        <div>
            <img style={{width: 200}} src={Image} alt="Submitted!" />
        </div>

        <h2 className='display-2 my-3'>Thank you!</h2>
        <p><b>Your form submission has been received, you may now close this page.</b></p>

    </div>
)

export default Submitted;