
import { memo, useState, useCallback } from 'react';
import { FormGroup, Input, Row, Col } from 'reactstrap'

import StateInput from 'components/markup/inputs/States';

const QuestionContactAddress = ({question, answers, onSaveQuestion, hasErr}) => {

    const [ answer, setAnswer ] = useState(answers[question.id] || {
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        postal_code: '',
    })

    const onChange = useCallback((field, value) => {
        const _answer = JSON.parse(JSON.stringify(answer));
        _answer[field] = value;

        setAnswer(_answer);
    }, [answer])

    const onSave = useCallback(async () => {
        onSaveQuestion(answer, question.id)
    }, [answer, onSaveQuestion, question.id])
   
    const onStateSave = useCallback(async (state) => {
        onSaveQuestion({
            ...answer,
            state
        }, question.id)

    }, [answer, onSaveQuestion, question.id])

    return (
        <div className={hasErr ? 'question-error' : ''}>
            <h2>{question.name}</h2>
            {question.description ? <p className='text-sm mb-0'>{question.description}</p> : ''}

            <Row>
                <Col md={6}>
                    <FormGroup>
                        <label className="form-control-label">Address Line 1</label>
                        <Input 
                            onBlur={onSave}
                            onChange={e => onChange('address_line_1', e.target.value)}
                            value={answer.address_line_1}
                            type="text"
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <label className="form-control-label">Address Line 2</label>
                        <Input 
                            onBlur={onSave}
                            onChange={e => onChange('address_line_2', e.target.value)}
                            value={answer.address_line_2}
                            type="text"
                        />
                    </FormGroup>
                </Col>
            </Row>
           
            <Row>
                <Col md={4}>
                    <FormGroup className='mt-3'>
                        <label className="form-control-label">City</label>
                        <Input 
                            onBlur={onSave}
                            onChange={e => onChange('city', e.target.value)}
                            value={answer.city}
                            type="text"
                        />
                    </FormGroup>
                </Col>

                <Col md={4}>
                    <div className='mt-3'>
                        <StateInput 
                            title="State"
                            value={answer.state}
                            placeholder=" "
                            onChange={(state) => {
                                onChange('state', state)
                                onStateSave(state)
                            }}
                        />
                    </div>
                </Col>

                <Col md={4}>
                    <FormGroup className='mt-3'>
                        <label className="form-control-label">Zip</label>
                        <Input 
                            onBlur={onSave}
                            onChange={e => onChange('postal_code', e.target.value)}
                            value={answer.postal_code}
                            type="text"
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    )
}

export default memo(QuestionContactAddress);