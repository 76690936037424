import { useCallback, useEffect, useState } from 'react';

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Forms from 'views/form/layout';
import Errors from 'views/errors/layout';
import FailedConnection from 'views/errors/pages/errors/FailedConnection';

import StandardLoader from 'components/markup/system/StandardLoader'
import { ToastContainer } from 'react-toastify';

import { setLoaded } from 'store/functions/load/load';

const App = () => {

    const [ loading, setLoading ] = useState(true)
    const [ err, setErr ] = useState(false)

    const load = useCallback(async () => {
        const data = await setLoaded();
        if(!data.success) return setErr(true);
        return setLoading(false);
    }, [])

    useEffect(() => {
        load()
    }, [load])

    if(err) return <FailedConnection />;
    if(loading) return <div></div>

    return (
        <div>
            <StandardLoader />
            <ToastContainer autoClose={2000} />
            
            <BrowserRouter>
                <Switch>
                    <Route path="/errors"  render={props => <Errors  {...props} /> } />
                    <Route path="/"   render={props => <Forms    {...props} />  } />
                </Switch>
            </BrowserRouter>

        </div>
    )
}

export default App