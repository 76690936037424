
import { memo, useState, useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import MapQuestions from '../MapQuestions';

const QuestionSelect = ({question, answers, onSaveQuestion, custom_fields, newLoop, errors, hasErr}) => {

    const [ answer, setAnswer ] = useState(answers[question.id] || '')

    const onSave = useCallback(async (_answer) => {
        onSaveQuestion(_answer, question.id)
    }, [onSaveQuestion, question.id])

    return (
        <div className={hasErr ? 'question-error' : ''}>

            <ReactSelect
                title={question.name}
                description={question.description ? question.description : ''}
                formGroup={true}
                placeholder=""
                onChange={(obj) => {
                    setAnswer(obj.value)
                    onSave(obj.value)
                }}
                options={[
                    ...[{ label: '-', value: '' }],
                    ...question.answers.map(a => {
                        return { label: a, value: a }
                    })
                ]}
                value={answer}
            />    

            {answer && question.branch_on_answer && question.branch_on_answers.includes(answer) ? (
                <MapQuestions 
                    parentLoop={newLoop}
                    questions={question.questions}
                    answers={answers}
                    custom_fields={custom_fields}
                    onSaveQuestion={onSaveQuestion}
                    errors={errors}
                    hasErr={errors.includes(question.id)}
                />
            ) : ''}

        </div>
    )
}

export default memo(QuestionSelect);